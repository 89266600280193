// extracted by mini-css-extract-plugin
export var bgGrey100 = "TwoCtaImgBoxes-module--bg-grey-100--f8bae";
export var bgGrey150 = "TwoCtaImgBoxes-module--bg-grey-150--68684";
export var bgGrey200 = "TwoCtaImgBoxes-module--bg-grey-200--2b0e7";
export var bgGrey300 = "TwoCtaImgBoxes-module--bg-grey-300--a9708";
export var bgGrey400 = "TwoCtaImgBoxes-module--bg-grey-400--7d1eb";
export var bgGrey500 = "TwoCtaImgBoxes-module--bg-grey-500--ede75";
export var bgGrey600 = "TwoCtaImgBoxes-module--bg-grey-600--bd00b";
export var bgGrey700 = "TwoCtaImgBoxes-module--bg-grey-700--5aa89";
export var bgGrey800 = "TwoCtaImgBoxes-module--bg-grey-800--bde82";
export var bgGrey900 = "TwoCtaImgBoxes-module--bg-grey-900--09635";
export var bkgdImg2Cls = "TwoCtaImgBoxes-module--bkgdImg2Cls--38d51";
export var bkgdImgCls = "TwoCtaImgBoxes-module--bkgdImgCls--d28c2";
export var boxWrapperCls = "TwoCtaImgBoxes-module--boxWrapperCls--9a509";
export var doubleLineTitleCls = "TwoCtaImgBoxes-module--doubleLineTitleCls--8263e";
export var foreImg2Cls = "TwoCtaImgBoxes-module--foreImg2Cls--ef283";
export var foreImgCls = "TwoCtaImgBoxes-module--foreImgCls--4debb";
export var textGrey100 = "TwoCtaImgBoxes-module--text-grey-100--873b4";
export var textGrey150 = "TwoCtaImgBoxes-module--text-grey-150--29ad8";
export var textGrey200 = "TwoCtaImgBoxes-module--text-grey-200--2cbee";
export var textGrey300 = "TwoCtaImgBoxes-module--text-grey-300--fa30d";
export var textGrey400 = "TwoCtaImgBoxes-module--text-grey-400--08f2c";
export var textGrey500 = "TwoCtaImgBoxes-module--text-grey-500--90a68";
export var textGrey600 = "TwoCtaImgBoxes-module--text-grey-600--a2234";
export var textGrey700 = "TwoCtaImgBoxes-module--text-grey-700--7f203";
export var textGrey800 = "TwoCtaImgBoxes-module--text-grey-800--efeba";
export var textGrey900 = "TwoCtaImgBoxes-module--text-grey-900--a4d6d";
export var title2Cls = "TwoCtaImgBoxes-module--title2Cls--67c82";
export var title2Line2Cls = "TwoCtaImgBoxes-module--title2Line2Cls--fb933";
export var titleLine1Cls = "TwoCtaImgBoxes-module--titleLine1Cls--65879";
export var titleLine2Cls = "TwoCtaImgBoxes-module--titleLine2Cls--37847";