import { graphql } from "gatsby"
import React from "react"

import Section from "../../UI/Common/Section"

import TwoCtaImgBoxesComponent from "./TwoCtaImgBoxes"

export const fragment = graphql`
  fragment TwoCtaImgBoxesFragment on WpPage_Flexlayouts_FlexibleLayouts_TwoCtaImgBoxes {
    imageBoxes {
      largeTitle {
        lineOne
        lineTwo
        link {
          url
          target
        }
      }
      backgroundImg {
        desktop {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 1073
                placeholder: NONE
              )
            }
          }
        }
        mobile {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 937
                placeholder: NONE
              )
            }
          }
        }
      }
      foregroundImg {
        desktop {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                height: 765
                placeholder: NONE
              )
            }
          }
        }
        mobile {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                height: 320
                placeholder: NONE
              )
            }
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFTwoCtaImgBoxes = ({ imageBoxes, config }) => (
  <TwoCtaImgBoxesComponent imageBoxes={imageBoxes} config={config} />
)
