import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import { Section, Link } from "../../UI/Common"

import {
  boxWrapperCls,
  bkgdImgCls,
  titleLine1Cls,
  titleLine2Cls,
  doubleLineTitleCls,
  title2Line2Cls,
  foreImgCls,
  bkgdImg2Cls,
  title2Cls,
  foreImg2Cls,
} from "./TwoCtaImgBoxes.module.scss"

const TwoCtaImgBoxes = ({ imageBoxes, config }) => {
  const { padding } = config || {}

  return (
    <Section padding={padding}>
      <Row>
        {imageBoxes && imageBoxes.map((box, i) => {
          const {
            largeTitle: title,
            backgroundImg: { mobile: mobileBkgdImg, desktop: desktopBkgdImg },
            foregroundImg: { mobile: mobileForeImg, desktop: desktopForeImg },
          } = box

          let bkgdImgStyles = bkgdImgCls
          let foreImgStyles = foreImgCls

          if (i === 1) {
            bkgdImgStyles = `${bkgdImgCls} ${bkgdImg2Cls}`
            foreImgStyles = `${foreImgCls} ${foreImg2Cls}`
          }

          let line1Styles = titleLine1Cls
          let line2Styles = titleLine2Cls

          if (Object.keys(title).length > 1 && i === 1) {
            line1Styles = `${titleLine1Cls} ${doubleLineTitleCls} ${title2Cls}`
            line2Styles = `${titleLine2Cls} ${title2Line2Cls}`
          } else if (Object.keys(title).length > 1) {
            line1Styles = `${titleLine1Cls} ${doubleLineTitleCls}`
          }

          return (
            <Col className="px-0" xs={12} lg={6} key={uuidv4()}>
              <Link link={title.link.url}>
                <div className={boxWrapperCls}>
                  <GatsbyImage
                    className={`d-lg-none ${bkgdImgStyles}`}
                    style={{ position: "absolute" }}
                    alt={mobileBkgdImg.altText}
                    image={getImage(mobileBkgdImg.localFile)}
                  />
                  <GatsbyImage
                    className={`d-none d-lg-block ${bkgdImgStyles}`}
                    style={{ position: "absolute" }}
                    alt={desktopBkgdImg.altText}
                    image={getImage(desktopBkgdImg.localFile)}
                  />

                  <div className={line1Styles}>{title.lineOne}</div>
                  {title.lineTwo && (
                    <div className={line2Styles}> {title.lineTwo}</div>
                  )}

                  <GatsbyImage
                    className={`d-lg-none ${foreImgStyles}`}
                    style={{ position: "absolute" }}
                    alt={mobileForeImg.altText}
                    image={getImage(mobileForeImg.localFile)}
                  />
                  <GatsbyImage
                    className={`d-none d-lg-block ${foreImgStyles}`}
                    style={{ position: "absolute" }}
                    alt={desktopForeImg.altText}
                    image={getImage(desktopForeImg.localFile)}
                  />
                </div>
              </Link>
            </Col>
          )
        })}
      </Row>
    </Section>
  )
}

export default TwoCtaImgBoxes
